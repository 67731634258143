import { useContext, useMemo } from "react";
import Markdown from "react-markdown";
import remarkGfm from "remark-gfm";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import dracula from "react-syntax-highlighter/dist/esm/styles/prism/dracula.js";

import styles from "../styles/ChatResponse.module.scss";
import "../styles/markdown.scss";

import spinner from "../img/spinner.svg";
import stopIcon from "../img/stop.svg";

import { ApplicationContext } from "./contexts/ApplicationContext.js";
import { assistantAzureRegexCheck } from "./functions/Chat/assistantAzureRegexCheck.js";

const ChatResponse = ({
  content,
  mode,
  waiting,
  index,
  handleAbortStream,
  uniqueChatId,
}) => {
  const { applicationConfig } = useContext(ApplicationContext);

  const modeConfig = applicationConfig.modes.find((m) => m.name === mode);

  const icon = modeConfig?.img || "";
  const label = modeConfig?.label || "";

  const isLoading =
    waiting &&
    (!(
      mode === "GENERAL" ||
      mode === "GENERAL_AZURE" ||
      assistantAzureRegexCheck(mode)
    ) ||
      ((mode === "GENERAL" ||
        mode === "GENERAL_AZURE" ||
        assistantAzureRegexCheck(mode)) &&
        content.length === 0));
  const isImageGeneration =
    mode === "IMAGE_GENERATION" || mode === "IMAGE_GENERATION_AZURE";

  const sanitizeBase64Image = (url) => {
    // Only allow specific image MIME types
    const allowedMimeTypes = [
      "image/png",
      "image/jpeg",
      "image/gif",
      "image/webp",
    ];
    const mimeTypeMatch = url.match(/^data:(image\/[a-zA-Z+]+);base64,/);

    if (mimeTypeMatch && allowedMimeTypes.includes(mimeTypeMatch[1])) {
      // Validate base64 content
      const base64Content = url.split(",")[1];
      if (base64Content && /^[A-Za-z0-9+/=]+$/.test(base64Content)) {
        return url;
      }
    }

    console.warn("Invalid or potentially unsafe image data:", url);
    return "";
  };

  const urlTransform = useMemo(
    () => (url) => {
      if (url.startsWith("data:image")) {
        return sanitizeBase64Image(url);
      }

      return url;
    },
    [content]
  );

  const disallowedElements = useMemo(
    () => [
      "script",
      "style",
      "iframe",
      "frame",
      "object",
      "embed",
      "form",
      "input",
      "button",
      "textarea",
      "select",
      "option",
      "applet",
      "meta",
      "base",
      "link",
      "canvas",
      "audio",
      "video",
      "noscript",
      "template",
    ],
    []
  );

  return (
    <div key={index} className={styles.container}>
      <div className={styles.topper}>
        <div className={styles.hej}>
          <img src={icon} alt="Hej ikon" width={40} />
          <span>{label}</span>
        </div>
        {waiting &&
          (mode === "GENERAL" ||
            mode === "GENERAL_AZURE" ||
            assistantAzureRegexCheck(mode)) && (
            <button onClick={() => handleAbortStream(uniqueChatId)}>
              <img src={stopIcon} alt="Stoppa" width={30} />
            </button>
          )}
      </div>
      <p
        className={styles.padded}
        style={{
          padding: isImageGeneration && !waiting && "17px 0px",
        }}
      >
        {isLoading ? (
          <img src={spinner} alt="Spinner" width={30} />
        ) : isImageGeneration ? (
          <img className={styles.image} src={content} alt="Genererad bild" />
        ) : (
          <div className="markdown-body">
            <Markdown
              remarkPlugins={[remarkGfm]}
              urlTransform={urlTransform}
              disallowedElements={disallowedElements}
              children={content}
              components={{
                code(props) {
                  const { children, className, node, ...rest } = props;
                  const match = /language-(\w+)/.exec(className || "");
                  return match ? (
                    <SyntaxHighlighter
                      {...rest}
                      PreTag="div"
                      children={String(children).replace(/\n$/, "")}
                      language={match[1]}
                      style={dracula}
                    />
                  ) : (
                    <code {...rest} className={className}>
                      {children}
                    </code>
                  );
                },
              }}
            />
          </div>
        )}
      </p>
    </div>
  );
};

export default ChatResponse;
